export default defineNuxtRouteMiddleware((to) => {
  const { $pinia } = useNuxtApp();
  const onboardingStore = useOnboardingStore($pinia);
  const {
    app: { baseURL },
  } = useRuntimeConfig();

  // Only apply this middleware to the onboarding route
  if (!to.path.startsWith("/onboarding")) {
    return;
  }

  if (to.query.skip_onboarding === "true") {
    onboardingStore.registrationParams = onboardingStore.getDefaultPartialRegistrationParams();
    onboardingStore.skippedOnboarding = true;

    return navigateTo(`${baseURL}/onboarding/checkout`, { external: true });
  }
});
